<script>
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('user', ['setIsUserLoggedIn']),
  },

  created() {
    this.logout();
    this.setIsUserLoggedIn(false);
  },

  render() {
    return null;
  },
};
</script>
